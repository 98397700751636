// Chakra imports
import {
  AvatarGroup,
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
// Assets
import React, { useState } from "react";
import { IoHeart, IoHeartOutline } from "react-icons/io5";

export default function NFT(props) {
  const { image, name, author1, author2, author3, bidders, download, currentbid } = props;
  const [like, setLike] = useState(false);
  const textColor = useColorModeValue("navy.700", "white");
  const textColorBid = useColorModeValue("brand.500", "white");
  return (
    <Card p='20px'>
      <Flex direction={{ base: "column" }} justify='center'>
        <Box mb={{ base: "20px", "2xl": "20px" }} position='relative'>
          <Image
            src={image}
            w={{ base: "100%", "3xl": "100%" }}
            h={{ base: "100%", "3xl": "100%" }}
            borderRadius='20px'
          />
        </Box>
        <Flex flexDirection='column' justify='space-between' h='100%'>
          <Flex
            justify='space-between'
            direction={{
              base: "row",
              md: "column",
              lg: "row",
              xl: "column",
              "2xl": "row",
            }}
            mb='auto'>
            <Flex direction='column'>
              <Text
                color={textColor}
                fontSize={{
                  base: "xl",
                  md: "lg",
                  lg: "lg",
                  xl: "lg",
                  "2xl": "md",
                  "3xl": "lg",
                }}
                mb='5px'
                fontWeight='bold'
                me='14px'>
                {name}
              </Text>
              <Text
              mb='8px'
                color='rgba(0, 0, 0, 0.8)'
                fontSize={{
                  base: "sm",
                }}
                textAlign="justify"
                fontWeight='400'
                me='14px'>
               1️⃣ {author1}
              </Text>
              <Text
              mb='8px'
                color='rgba(0, 0, 0, 0.8)'
                fontSize={{
                  base: "sm",
                }}
                textAlign="justify"
                fontWeight='400'
                me='14px'>
               2️⃣ {author2}
              </Text>
              <Text
              mb='8px'
                color='rgba(0, 0, 0, 0.8)'
                fontSize={{
                  base: "sm",
                }}
                textAlign="justify"
                fontWeight='400'
                me='14px'>
               3️⃣ {author3}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
}
