// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
// Custom components
import BarChart from "components/charts/BarChart";
import React from "react";
import {
  barChartDataConsumption,
  barChartOptionsConsumption,
} from "variables/charts";
import { MdBarChart } from "react-icons/md";

export default function WeeklyRevenue(props) {
  const { ...rest } = props;

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("brand.500", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );
  return (
    <Card align='center' direction='column' w='100%' {...rest}>
      {/* <Flex align='center' w='100%' px='15px' py='10px'>
        <Text
          me='auto'
          color={textColor}
          fontSize='xl'
          fontWeight='700'
          lineHeight='100%'>
          Weekly Revenue
        </Text>
        <Button
          align='center'
          justifyContent='center'
          bg={bgButton}
          _hover={bgHover}
          _focus={bgFocus}
          _active={bgFocus}
          w='37px'
          h='37px'
          lineHeight='100%'
          borderRadius='10px'
          {...rest}>
          <Icon as={MdBarChart} color={iconColor} w='24px' h='24px' />
        </Button>
      </Flex> */}

      <Box h='330px' mt='auto'>
      <Text
          me='auto'
          color={textColor}
          fontSize='17'
          fontWeight='250'
          lineHeight='160%'
          textAlign="justify">
         OrthCVMS adalah aplikasi inovatif yang dirancang untuk menganalisis tahapan maturasi cervical vertebrae menggunakan foto rontgen sefalometri dengan menentukan titik titik penting pada gambaran vertebra C2,C3 dan C4
OrthCVMS secara otomatis akan mengevaluasi bentuk morfologi vertebra dan memberikan hasil berupa klasifikasi tahapan maturasi skeletal, hal ini bermanfaat bagi dokter gigi atau dokter gigi spesialis ortodonti untuk menentukan rencana perawatan ortodonti pada pasien yang sedang dalam masa tumbuh kembang
</Text>
      </Box>
    </Card>
  );
}
