// Chakra imports
import {
  Box,
  Button,
  Flex,
  Modal,        // Import Modal dari Chakra UI
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure, 
  Text,
  Grid,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React, { useRef, useState, useEffect } from "react";
import Swal from 'sweetalert2'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import 'jspdf-autotable';

export default function Upload(props) {
  const { used, total, ...rest } = props;
  const canvasRef = useRef(null);
  const containerRef = useRef(null);


  const [zoomLevel, setZoomLevel] = useState(1); // State untuk menyimpan level zoom
  const [radiographImage, setRadiographImage] = useState(null); // State untuk menyimpan gambar
  const [c2Points, setC2Points] = useState([]); // State untuk C2 points
  const [c3Points, setC3Points] = useState([]); // State untuk C3 points
  const [c4Points, setC4Points] = useState([]); // State untuk C4 points
  const [c2Index, setC2Index] = useState(0);
  const [c3Index, setC3Index] = useState(0);
  const [c4Index, setC4Index] = useState(0);

    // State untuk undo/redo
    const [undoStack, setUndoStack] = useState([]);
    const [redoStack, setRedoStack] = useState([]);
    const [canvasDimensions, setCanvasDimensions] = useState({ width: 670, height: 600 });
    const { isOpen, onOpen, onClose } = useDisclosure();  // State untuk modal
    const [analysisResults, setAnalysisResults] = useState({
      angleC2: null,
      ConcvC2: null,
      angleC3: null,
      ConcvC3: null,
      angleC4: null,
      ConcvC4: null,
      BARC3: null,
      BARC4: null,
      PARC3: null,
      PARC4: null,
      MorfologiC3: null,
      MorfologiC4: null,
      CS: null,
      CSDescription: null,
    });

  useEffect(() => {
    if (radiographImage) {
      drawImageWithZoomAndDrawing(radiographImage, zoomLevel, c2Points, c3Points, c4Points);
    }
  }, [c2Points, c3Points, c4Points, zoomLevel, radiographImage]);

    // Function to handle resizing the canvas responsively
    useEffect(() => {
      const updateCanvasSize = () => {
        if (containerRef.current) {
          const containerWidth = containerRef.current.offsetWidth;
          const containerHeight = containerWidth * (600 / 670); // Maintain aspect ratio
          setCanvasDimensions({ width: containerWidth, height: containerHeight });
        }
      };
  
      // Initial sizing
      updateCanvasSize();
  
      // Update canvas size when window is resized
      window.addEventListener("resize", updateCanvasSize);
      return () => window.removeEventListener("resize", updateCanvasSize);
    }, []);
  

  const handleOpenButtonClick = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';

    input.onchange = (event) => {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = () => {
        const img = new Image();
        img.onload = () => {
          setRadiographImage(img);
          drawImageWithZoomAndDrawing(img, zoomLevel); // Gambar gambar dengan zoom dan drawing
        };
        img.src = reader.result;
      };

      reader.readAsDataURL(file);
    };

    input.click();
  };

  const drawImageWithZoomAndDrawing = (image, zoom) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const aspectRatio = image.width / image.height;
    let drawWidth = canvas.width * zoom;
    let drawHeight = canvas.height * zoom;

    if (canvas.width / canvas.height > aspectRatio) {
      drawWidth = canvas.height * aspectRatio * zoom;
    } else {
      drawHeight = canvas.width / aspectRatio * zoom;
    }

    const xOffset = (canvas.width - drawWidth) / 2;
    const yOffset = (canvas.height - drawHeight) / 2;

    ctx.drawImage(image, xOffset, yOffset, drawWidth, drawHeight);
    drawPointsAndLines(ctx);
  };

  const drawPointsAndLines = (ctx) => {
    ctx.strokeStyle = 'blue';
    ctx.fillStyle = 'red';
    ctx.lineWidth = 2;

    const drawCircle = (point) => {
      ctx.beginPath();
      ctx.arc(point.x, point.y, 4, 0, Math.PI * 2);
      ctx.fill();
    };

    const drawLine = (point1, point2) => {
      ctx.beginPath();
      ctx.moveTo(point1.x, point1.y);
      ctx.lineTo(point2.x, point2.y);
      ctx.stroke();
    };

    for (let i = 0; i < c2Index; i++) {
      drawCircle(c2Points[i]);
      if (i > 0) {
        drawLine(c2Points[i - 1], c2Points[i]);
      }
    }

    for (let i = 0; i < c3Index; i++) {
      drawCircle(c3Points[i]);
      if (i > 0) {
        drawLine(c3Points[i - 1], c3Points[i]);
      }
    }

    for (let i = 0; i < c4Index; i++) {
      drawCircle(c4Points[i]);
      if (i > 0) {
        drawLine(c4Points[i - 1], c4Points[i]);
      }
    }
  };
  
  const handleCanvasClick = (event) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
  
    // Mendapatkan ukuran canvas saat ini dan ukuran asli
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;
  
    // Koordinat x dan y yang diproyeksikan secara proporsional
    const x = (event.clientX - rect.left) * scaleX;
    const y = (event.clientY - rect.top) * scaleY;
  
    const point = { x, y };
  
    setUndoStack([...undoStack, { c2Points, c3Points, c4Points, c2Index, c3Index, c4Index }]);
    setRedoStack([]);
  
    if (c2Index < 3) {
      setC2Points([...c2Points, point]);
      setC2Index(c2Index + 1);
    } else if (c3Index < 5) {
      setC3Points([...c3Points, point]);
      setC3Index(c3Index + 1);
    } else if (c4Index < 5) {
      setC4Points([...c4Points, point]);
      setC4Index(c4Index + 1);
    }
  };

  // State untuk menyimpan hasil perhitungan dari setiap bagian
let angleC2, angleC3, angleC4;
let BARC3, BARC4, PARC3, PARC4, squareC3, squareC4;

const analisis = () => {
  calculateC2Measurements();
  calculateC3Measurements();
  calculateC4Measurements();
  calculateCS();
  Swal.fire({
    position: "top-end",
    icon: "success",
    title: "Analisis Cervical Stage selesai!",
    showConfirmButton: true,
    confirmButtonText: "Lihat hasil!"
  }).then((result) => {
    if (result.isConfirmed) {
      onOpen();
    }
  });
};

// Placeholder untuk fungsi kalkulasi (sesuaikan dengan kebutuhan)
const calculateC2Measurements = () => {
  if (c2Points.length < 3) {
    console.error("Kurang dari 3 titik untuk C2.");
    return;
  }
  console.log(c2Points[0], c2Points[1], c2Points[2]);
  angleC2 = calculateConcavityAngle(c2Points[0], c2Points[1], c2Points[2]);
  const concavityC2 = ConcavityIdentify(angleC2);
  console.log("C2 Measurements - Angle:", angleC2, "Concavity:", concavityC2);
  // Simpan hasil ke dalam state
  setAnalysisResults(prevResults => ({
    ...prevResults,
    angleC2: angleC2,
    ConcvC2: concavityC2
  }));
};

const calculateC3Measurements = () => {
  if (c3Points.length < 5) {
    console.error("Kurang dari 5 titik untuk C3.");
    return;
  }
  angleC3 = calculateConcavityAngle(c3Points[0], c3Points[1], c3Points[2]);
  const concavityC3 = ConcavityIdentify(angleC3);
  BARC3 = BARIdentify(c3Points[0], c3Points[3], c3Points[2]);
  PARC3 = PARIdentify(c3Points[4], c3Points[0], c3Points[3], c3Points[2]);
  const widthC3 = calculateDistance(c3Points[0], c3Points[2]);
  const heightC3 = calculateDistance(c3Points[0], c3Points[4]);
  const heightC3a = calculateDistance(c3Points[2], c3Points[3]);
  squareC3 = widthC3 / heightC3;
  const MorfologiC3 = Morfologi(squareC3, BARC3, PARC3, heightC3, heightC3a);
  console.log("C3 Measurements - Angle:", angleC3, "Concavity:", concavityC3, squareC3, BARC3, PARC3, MorfologiC3);
  setAnalysisResults(prevResults => ({
    ...prevResults,
    angleC3: angleC3,
    ConcvC3: concavityC3,
    BARC3: BARC3,
    PARC3: PARC3,
    MorfologiC3: MorfologiC3
  }));
};

const calculateC4Measurements = () => {
  if (c4Points.length < 5) {
    console.error("Kurang dari 5 titik untuk C4.");
    return;
  }
  angleC4 = calculateConcavityAngle(c4Points[0], c4Points[1], c4Points[2]);
  const concavityC4 = ConcavityIdentify(angleC4);
  BARC4 = BARIdentify(c4Points[0], c4Points[3], c4Points[2]);
  PARC4 = PARIdentify(c4Points[4], c4Points[0], c4Points[3], c4Points[2]);
  const widthC4 = calculateDistance(c4Points[0], c4Points[2]);
  const heightC4 = calculateDistance(c4Points[0], c4Points[4]);
  const heightC4a = calculateDistance(c4Points[2], c4Points[3]);
  squareC4 = widthC4 / heightC4;
  const MorfologiC4 = Morfologi(squareC4, BARC4, PARC4, heightC4, heightC4a);
  console.log("C4 Measurements - Angle:", angleC4, "Concavity:", concavityC4, squareC4, BARC4, PARC4, MorfologiC4);

  setAnalysisResults(prevResults => ({
    ...prevResults,
    angleC4: angleC4,
    ConcvC4: concavityC4,
    BARC4: BARC4,
    PARC4: PARC4,
    MorfologiC4: MorfologiC4
  }));
};

const calculateCS = () => {
  // Hitung CS berdasarkan hasil perhitungan yang telah dilakukan di C2, C3, dan C4
  const C2ConcScore = angleC2 >= 0.60 ? 1 : 0;
  const C3ConcScore = angleC3 >= 0.66 ? 1 : 0;
  const C4ConcScore = (angleC4 >= 0.69 || PARC3 <= 1) ? 1 : 0;
  const C3BARScore = BARC3 <= 1.30 ? 1 : 0;
  const C4BARScore = BARC4 <= 1.12 ? 1 : 0;

  // Kalkulasi total nilai CS
  const CS = C2ConcScore + C3ConcScore + C4ConcScore + C3BARScore + C4BARScore + 1;

  // Tampilkan hasil CS
  console.log("Nilai CS:", CS);
  console.log(C2ConcScore, C3ConcScore, C4ConcScore, C3BARScore, C4BARScore);

  let CSDescription = '';
  switch (CS) {
    case 1:
      CSDescription = 'Stage ini terjadi sejak periode gigi sulung sampai sekitar 2 tahun sebelum puncak pertumbuhan kraniofasial';
      break;
    case 2:
      CSDescription = 'Puncak pertumbuhan mandibula akan terjadi 1 tahun setelah tahap ini';
      break;
    case 3:
      CSDescription = 'Pada tahap ini percepatan pertumbuhan maksimal kraniofasial sudah diantisipasi';
      break;
    case 4:
      CSDescription = 'Pada tahap ini kelanjutan percepatan pertumbuhan kraniofasial selanjutnya dapat diantisipasi';
      break;
    case 5:
      CSDescription = 'Pada tahap ini pertumbuhan kraniofasial yang substansial sudah terjadi';
      break;
    case 6:
      CSDescription = 'Puncak pertumbuhan telah berakhir';
      break;
    default:
      CSDescription = 'Tidak ada deskripsi untuk nilai CS ini';
      break;
  }

  setAnalysisResults(prevResults => ({
    ...prevResults,
    CS: CS,
    CSDescription: CSDescription
  }));
};

  const calculateConcavityAngle = (p1, p2, p3) => {
    const a = calculateDistance(p2, p3);
    const b = calculateDistance(p1, p3);
    const c = calculateDistance(p1, p2);

    const angle = Math.acos((b * b + c * c - a * a) / (2 * b * c));
    return angle * (180 / Math.PI); // Convert to degrees
  };

  const ConcavityIdentify = (angle) => angle >= 0.60 ? "Cekung" : "Datar";

  // Function to calculate the distance between two points
  const calculateDistance = (p1, p2) => {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
  };

  // Function to calculate BAR
  const BARIdentify = (lp, ua, la) => {
    return calculateDistance(lp, la) / calculateDistance(ua, la);
  };

  // Function to calculate PAR
  const PARIdentify = (up, lp, ua, la) => {
    return calculateDistance(up, lp) / calculateDistance(ua, la);
  };

  const Morfologi = (square, bar, par, heightp, heighta) => {
    let bentuk;
      if (bar >= 1.3 && par >= 1 && heightp > heighta && heightp / heighta > 1.5 )
      {
          bentuk = "Trapesium";
      }
      else
      {
          if(square === 1)
          {
              bentuk = "Persegi";
          }
          else
          {
              bentuk = square < 1 ? "Rectangular Vertikal" : "Rectangular Horizontal";
          }
      }
      return bentuk;
  };



  // Fungsi untuk memperbesar (zoom in)
  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => {
      const newZoom = prevZoom + 0.1;
      if (radiographImage) {
        drawImageWithZoomAndDrawing(radiographImage, newZoom);
      }
      return newZoom;
    });
  };

  // Fungsi untuk memperkecil (zoom out)
  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => {
      const newZoom = prevZoom - 0.1 > 0 ? prevZoom - 0.1 : 0.1; // Hindari zoom 0 atau negatif
      if (radiographImage) {
        drawImageWithZoomAndDrawing(radiographImage, newZoom);
      }
      return newZoom;
    });
  };

    // Fungsi undo
    const handleUndo = () => {
      if (undoStack.length > 0) {
        const lastState = undoStack.pop();
        setRedoStack([...redoStack, { c2Points, c3Points, c4Points, c2Index, c3Index, c4Index }]);
        setC2Points(lastState.c2Points);
        setC3Points(lastState.c3Points);
        setC4Points(lastState.c4Points);
        setC2Index(lastState.c2Index);
        setC3Index(lastState.c3Index);
        setC4Index(lastState.c4Index);
      }
    };
  
    // Fungsi redo
    const handleRedo = () => {
      if (redoStack.length > 0) {
        const nextState = redoStack.pop();
        setUndoStack([...undoStack, { c2Points, c3Points, c4Points, c2Index, c3Index, c4Index }]);
        setC2Points(nextState.c2Points);
        setC3Points(nextState.c3Points);
        setC4Points(nextState.c4Points);
        setC2Index(nextState.c2Index);
        setC3Index(nextState.c3Index);
        setC4Index(nextState.c4Index);
      }
    };

    const simpanData = () => {
      onClose();
      Swal.fire({
        title: 'Masukkan Data Pasien',
        html:
          '<input id="swal-input1" class="swal2-input" placeholder="Nama Pasien">' +
          '<input id="swal-input2" class="swal2-input" placeholder="Tanggal Lahir Pasien" type="date">' + // Ganti input menjadi tanggal lahir
          '<select id="swal-input3" class="swal2-input">' +
            '<option value="" disabled selected>Pilih Jenis Kelamin</option>' +
            '<option value="Laki-laki">Laki-laki</option>' +
            '<option value="Perempuan">Perempuan</option>' +
          '</select>',
        focusConfirm: false,
        preConfirm: () => {
          const namaPasien = document.getElementById('swal-input1').value;
          const tanggalLahirPasien = document.getElementById('swal-input2').value; // Ambil tanggal lahir
          const jenisKelamin = document.getElementById('swal-input3').value;
    
          if (namaPasien && tanggalLahirPasien && jenisKelamin) {
            generatePDF(namaPasien, tanggalLahirPasien, jenisKelamin);
          } else {
            Swal.showValidationMessage('Semua field harus diisi!');
          }
        }
      });
    };

    const calculateAge = (tanggalLahir) => {
      const today = new Date();
      const birthDate = new Date(tanggalLahir);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();
      
      if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    };
    
    const generatePDF = async (namaPasien, tanggalLahirPasien, jenisKelamin) => {
      const doc = new jsPDF('p', 'mm', 'a4');
      const canvas = canvasRef.current;
      
      // Ambil gambar dari kanvas menggunakan html2canvas
      const canvasImage = await html2canvas(canvas, { scale: 3 });
      const imgData = canvasImage.toDataURL('image/png');
      
      // Mendapatkan tanggal saat ini untuk disimpan di PDF
      const today = new Date();
      const tanggalSimpan = today.toLocaleDateString('id-ID', {
        day: 'numeric', month: 'long', year: 'numeric'
      });

        // Hitung umur berdasarkan tanggal lahir
      const umurPasien = calculateAge(tanggalLahirPasien);
    
       // Mengambil gambar logo sebagai data URL
      const logoPath = process.env.PUBLIC_URL + '/logo.png'; // Path gambar logo
      const logoData = await fetch(logoPath)
        .then(res => res.blob())
        .then(blob => new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(blob);
        }));
    
      // Membuat layout PDF
      doc.setFontSize(14);
      doc.text('Rekaman Medis Pasien', 105, 15, { align: 'center' });
      
      // Tambahkan logo di bagian kiri atas
      const logoX = 10;
      const logoY = 20;
    
      // Header informasi pasien
      doc.autoTable({
        startY: logoY, // Adjust the startY to be below the logo
        body: [
          [{ content: '', styles: { halign: 'center' } }, `Nama: ${namaPasien}\nTanggal Lahir: ${tanggalLahirPasien}\nUmur: ${umurPasien} tahun\nJenis Kelamin: ${jenisKelamin}\nTanggal Simpan: ${tanggalSimpan}`],
        ],
        theme: 'grid',
        headStyles: { fillColor: [22, 160, 133] },
        bodyStyles: { valign: 'middle' },
        columnStyles: {
          0: { cellWidth: 30, halign: 'center' }, // Column for the logo
          1: { cellWidth: 150, halign: 'left' }, // Column for patient info
        },
        didDrawCell: (data) => {
          // Insert logo in the first column of the first row
          if (data.row.index === 0 && data.column.index === 0) {
            const imgWidth = 18;
            const imgHeight = 18;
            const padding = 2; // Add some padding to center the image
            const imgX = data.cell.x + (data.cell.width - imgWidth) / 2;
            const imgY = data.cell.y + (data.cell.height - imgHeight) / 2;
    
            // Add the logo image to the cell
            doc.addImage(logoData, 'PNG', imgX, imgY, imgWidth, imgHeight);
          }
        },
      });
      // Membuat tabel untuk hasil analisis
      doc.setFontSize(12);
      doc.text('Hasil Analisis Cervical Stage:', 15, 50);
    
      // Tabel data
      doc.autoTable({
        startY: 52,
        head: [['Pengamatan', 'Nilai']],
        body: [
          ['C2 Angle', analysisResults.angleC2 !== null ? analysisResults.angleC2 : 'N/A'],
          ['Bentuk batas Inferior C2', analysisResults.ConcvC2 !== null ? analysisResults.ConcvC2 : 'N/A'],
          ['C3 Angle', analysisResults.angleC3 !== null ? analysisResults.angleC3 : 'N/A'],
          ['Bentuk batas Inferior C3', analysisResults.ConcvC3 !== null ? analysisResults.ConcvC3 : 'N/A'],
          ['C3 BAR', analysisResults.BARC3 !== null ? analysisResults.BARC3 : 'N/A'],
          ['C3 PAR', analysisResults.PARC3 !== null ? analysisResults.PARC3 : 'N/A'],
          ['Bentuk morfologi C3', analysisResults.MorfologiC3 !== null ? analysisResults.MorfologiC3 : 'N/A'],
          ['C4 Angle', analysisResults.angleC4 !== null ? analysisResults.angleC4 : 'N/A'],
          ['Bentuk batas Inferior C4', analysisResults.ConcvC4 !== null ? analysisResults.ConcvC4 : 'N/A'],
          ['C4 BAR', analysisResults.BARC4 !== null ? analysisResults.BARC4 : 'N/A'],
          ['C4 PAR', analysisResults.PARC4 !== null ? analysisResults.PARC4 : 'N/A'],
          ['Bentuk morfologi C4', analysisResults.MorfologiC4 !== null ? analysisResults.MorfologiC4 : 'N/A'],
          ['CS Score', analysisResults.CS !== null ? analysisResults.CS : 'N/A'],
          ['Keterangan CS', analysisResults.CSDescription !== null ? analysisResults.CSDescription : 'N/A'],
        ],
        theme: 'grid',
        headStyles: { fillColor: [22, 160, 133] },
        alternateRowStyles: { fillColor: [245, 245, 245] },
      });
    
      // Menambahkan gambar hasil analisis ke PDF
      const imgWidth = 130; // Ukuran gambar dalam mm
      const imgHeight = (canvasImage.height / canvasImage.width) * imgWidth; // Pertahankan rasio
      doc.addImage(imgData, 'PNG', 10, doc.autoTable.previous.finalY + 10, imgWidth, imgHeight);
    
      // Simpan PDF
      doc.save(`Rekaman_Medis_${namaPasien}.pdf`);
    };

   
    
    

  return (
    <>
          {/* Modal Popup */}
          <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Hasil Analisis CS pada gambar!</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
        <Grid templateColumns="150px 1fr" gap={2}>
          <Text as='b'>Analisis Cervikal 2</Text>
          <Text></Text>
          <Text>Angle C2:</Text>
          <Text>{analysisResults.angleC2 !== null ? analysisResults.angleC2 : 'N/A'}</Text>
          <Text>Concavity C2:</Text>
          <Text>{analysisResults.ConcvC2 !== null ? analysisResults.ConcvC2 : 'N/A'}</Text>

          <Text></Text>
          <Text></Text>
          <Text as='b'>Analisis Cervikal 3</Text>
          <Text></Text>
          <Text>Angle C3:</Text>
          <Text>{analysisResults.angleC3 !== null ? analysisResults.angleC3 : 'N/A'}</Text>
          <Text>Concavity C3:</Text>
          <Text>{analysisResults.ConcvC3 !== null ? analysisResults.ConcvC3 : 'N/A'}</Text>
          <Text>BAR C3:</Text>
          <Text>{analysisResults.BARC3 !== null ? analysisResults.BARC3 : 'N/A'}</Text>
          <Text>PAR C3:</Text>
          <Text>{analysisResults.PARC3 !== null ? analysisResults.PARC3 : 'N/A'}</Text>
          <Text>Morfologi C3:</Text>
          <Text>{analysisResults.MorfologiC3 !== null ? analysisResults.MorfologiC3 : 'N/A'}</Text>

          <Text></Text>
          <Text></Text>
          <Text as='b'>Analisis Cervikal 4</Text>
          <Text></Text>
          <Text>Angle C4:</Text>
          <Text>{analysisResults.angleC4 !== null ? analysisResults.angleC4 : 'N/A'}</Text>
          <Text>Concavity C4:</Text>
          <Text>{analysisResults.ConcvC4 !== null ? analysisResults.ConcvC4 : 'N/A'}</Text>
          <Text>BAR C4:</Text>
          <Text>{analysisResults.BARC4 !== null ? analysisResults.BARC4 : 'N/A'}</Text>
          <Text>PAR C4:</Text>
          <Text>{analysisResults.PARC4 !== null ? analysisResults.PARC4 : 'N/A'}</Text>
          <Text>Morfologi C4:</Text>
          <Text>{analysisResults.MorfologiC4 !== null ? analysisResults.MorfologiC4 : 'N/A'}</Text>

          <Text></Text>
          <Text></Text>
          <Text as='b'>Nilai CS:</Text>
          <Text as='b'>{analysisResults.CS !== null ? analysisResults.CS : 'N/A'}</Text>
          <Text as='b'>Keterangan CS:</Text>
          <Text as='b'>{analysisResults.CSDescription !== null ? analysisResults.CSDescription : 'N/A'}</Text>
        </Grid>
          </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" onClick={onClose}>
                Close
              </Button>
              <Button colorScheme="green" onClick={simpanData}>
                Simpan data
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Card {...rest} mb='20px' align='center' p='20px'>
      <Flex h='100%' direction={{ base: "column", "2xl": "row" }}>
      <Flex direction='column' pe='44px'>
          <Flex direction='row' mt='20px'>
          </Flex>
        </Flex>
        <Box>
        <canvas
            ref={canvasRef}
            width={canvasDimensions.width}
            height={canvasDimensions.height}
            style={{ border: '1px solid black', width: '100%', height: 'auto' }}
            onClick={handleCanvasClick}
          ></canvas>
        </Box>
        <Flex direction='column' pe='44px'>
          <Flex direction='row' pe='44px'>
            <Button onClick={handleZoomIn} me='20px'
              w='50px'
              minW='50px'
              mt={{ base: "20px", "2xl": "auto" }}
              variant='brand'
              fontWeight='500'>
              🔍 +
            </Button>
            <Button onClick={handleZoomOut} me='20px'
              w='50px'
              minW='50px'
              mt={{ base: "20px", "2xl": "auto" }}
              variant='brand'
              fontWeight='500'>
              🔎 -
            </Button>
            <Button onClick={handleUndo} me='20px'
              w='60px'
              minW='60px'
              mt={{ base: "20px", "2xl": "auto" }}
              variant='brand'
              fontWeight='500'>
              Undo
            </Button>
            <Button onClick={handleRedo} me='20px'
              w='60px'
              minW='60px'
              mt={{ base: "20px", "2xl": "auto" }}
              variant='brand'
              fontWeight='500'>
              Redo
            </Button>
          </Flex>
          <Flex direction='row' mt='20px'>
          <Button
              me='20px'
              w='140px'
              minW='140px'
              mt={{ base: "20px", "2xl": "auto" }}
              variant='brand'
              fontWeight='500'
              onClick={handleOpenButtonClick}
            >
              Buka gambar
            </Button>
            <Button
             me='20px'
             w='140px'
             minW='140px'
             mt={{ base: "20px", "2xl": "auto" }}
             colorScheme='green'
             fontWeight='500'
            onClick={analisis}
            >
              Analisis gambar
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Card>
    </>
  );
}