/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Text,
  useColorModeValue,
  SimpleGrid,
} from "@chakra-ui/react";

// Custom components
import Banner from "views/admin/marketplace/components/Banner";
import TableTopCreators from "views/admin/marketplace/components/TableTopCreators";
import HistoryItem from "views/admin/marketplace/components/HistoryItem";
import NFT from "components/card/NFT";
import Card from "components/card/Card.js";

// Assets
import Nft1 from "assets/img/nfts/1.png";
import Nft2 from "assets/img/nfts/2.png";
import Nft3 from "assets/img/nfts/3.png";
import Nft4 from "assets/img/nfts/4.png";
import Nft5 from "assets/img/nfts/5.png";
import Nft6 from "assets/img/nfts/6.png";
import Avatar1 from "assets/img/avatars/avatar1.png";
import Avatar2 from "assets/img/avatars/avatar2.png";
import Avatar3 from "assets/img/avatars/avatar3.png";
import Avatar4 from "assets/img/avatars/avatar4.png";
import tableDataTopCreators from "views/admin/marketplace/variables/tableDataTopCreators.json";
import { tableColumnsTopCreators } from "views/admin/marketplace/variables/tableColumnsTopCreators";

export default function Marketplace() {
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");
  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <Grid
        mb='20px'
        gridTemplateColumns={{ xl: "repeat(3, 1fr)", "1xl": "1fr 0.46fr" }}
        gap={{ base: "20px", xl: "20px" }}
        display={{ base: "block", xl: "grid" }}>
        <Flex
          flexDirection='column'
          gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}>
          <Flex direction='column'>
            <SimpleGrid columns={{ base: 1, md: 3 }} gap='20px'>
              <NFT
                author1 = "Pada Tahap 1, seluruh tepi bawah badan vertebra servikal C2, C3, dan C4 masih datar."
                author2 = "Badan vertebra C3 dan C4 memiliki bentuk trapezoid, mirip dengan potongan “wedge of cheese”, yang mencerminkan bahwa pergeseran morfologis awal pada vertebra belum terjadi."
                author3 = "Tahap ini berlangsung sejak periode gigi sulung dan berlangsung hingga sekitar dua tahun sebelum puncak pertumbuhan kraniofasial."
                
                name='CVMS Tahap 1'
                bidders={[
                  Avatar1,
                  Avatar2,
                  Avatar3,
                  Avatar4,
                  Avatar1,
                  Avatar1,
                  Avatar1,
                  Avatar1,
                ]}
                image={Nft1}
              />
              <NFT
                author1 = "Pada Tahap 2, cekungan mulai terbentuk di tepi bawah badan vertebra servikal C2."
                author2 = "Tepi bawah badan C3 dan C4 masih cenderung datar dan masih berbentuk trapezoid."
                author3 = "Puncak pertumbuhan skeletal diperkirakan akan terjadi satu tahun setelah tahap ini."
                
                name='CVMS Tahap 2'
                bidders={[
                  Avatar1,
                  Avatar2,
                  Avatar3,
                  Avatar4,
                  Avatar1,
                  Avatar1,
                  Avatar1,
                  Avatar1,
                ]}
                image={Nft2}
                currentbid='0.91 ETH'
                download='#'
              />
              <NFT
                author1 = "Pada Tahap 3, cekungan yang lebih jelas terbentuk di tepi bawah badan vertebra servikal C2 dan C3. Sementara itu, tepi bawah badan vertebra C4 masih cenderung datar."
                author2 = "Sebagian besar badan vertebra servikal C3 dan C4 tetap berbentuk trapezoid, meskipun beberapa sudah mulai menunjukkan bentuk persegi panjang horizontal (rectangular)."
                author3 = "Pada tahap ini, percepatan pertumbuhan maksimal kraniofasial telah diantisipasi, menandakan bahwa perkembangan tulang dan pertumbuhan gigi semakin mendekati periode kritis untuk intervensi ortodonti yang efektif."
                name='CVMS Tahap 3'
                bidders={[
                  Avatar1,
                  Avatar2,
                  Avatar3,
                  Avatar4,
                  Avatar1,
                  Avatar1,
                  Avatar1,
                  Avatar1,
                ]}
                image={Nft3}
                currentbid='0.91 ETH'
                download='#'
              />
              <NFT
                author1 = "Pada Tahap 4, cekungan pada tepi bawah badan vertebra servikal C2, C3, dan C4 semakin nyata."
                author2 = "Badan vertebra C3 dan C4 kini memiliki bentuk persegi panjang horizontal (rectangular horizontal) yang menyerupai 'bar of soap'."
                author3 = "Pada tahap ini, kelanjutan percepatan pertumbuhan kraniofasial selanjutnya dapat diantisipasi, menandakan bahwa pasien berada dalam fase kritis pertumbuhan, di mana intervensi ortodonti dapat dilakukan untuk memanfaatkan potensi pertumbuhan yang terjadi."
                name='CVMS Tahap 4'
                bidders={[
                  Avatar1,
                  Avatar2,
                  Avatar3,
                  Avatar4,
                  Avatar1,
                  Avatar1,
                  Avatar1,
                  Avatar1,
                ]}
                image={Nft4}
                currentbid='0.91 ETH'
                download='#'
              />
              <NFT
                author1 = "Pada Tahap 5, cekungan yang dalam telah terbentuk pada tepi bawah badan vertebra C2, C3, dan C4."
                author2 = "Badan vertebra C3 dan C4 kini memiliki bentuk yang lebih persegi, menyerupai 'marshmallow'."
                author3 = "Pada tahap ini, pertumbuhan kraniofasial yang substansial telah terjadi, menandakan bahwa perkembangan wajah dan struktur skeletal telah mencapai titik di mana intervensi ortodonti masih memungkinkan, meskipun hasilnya mungkin tidak seoptimal pada tahap-tahap sebelumnya."
                name='CVMS Tahap 5'
                bidders={[
                  Avatar1,
                  Avatar2,
                  Avatar3,
                  Avatar4,
                  Avatar1,
                  Avatar1,
                  Avatar1,
                  Avatar1,
                ]}
                image={Nft5}
                currentbid='0.91 ETH'
                download='#'
              />
              <NFT
                name='CVMS Tahap 6'
                author1 = "Pada Tahap 6, cekungan pada tepi bawah badan vertebra C2, C3, dan C4 masih terlihat jelas."
                author2 = "Setidaknya salah satu dari badan vertebra C3 dan C4 kini memiliki bentuk persegi panjang vertikal (rectangular vertical)."
                author3 = "Pada tahap ini, puncak pertumbuhan telah berakhir, menandakan bahwa proses perkembangan skeletal hampir selesai, dan intervensi ortodonti kemungkinan besar tidak akan memberikan perubahan yang berarti terhadap pola pertumbuhan yang telah ditetapkan."
                 bidders={[
                  Avatar1,
                  Avatar2,
                  Avatar3,
                  Avatar4,
                  Avatar1,
                  Avatar1,
                  Avatar1,
                  Avatar1,
                ]}
                image={Nft6}
                currentbid='0.91 ETH'
                download='#'
              />
            </SimpleGrid>
          </Flex>
        </Flex>
      </Grid>
      {/* Delete Product */}
    </Box>
  );
}
