// Chakra imports
import { Box, Text, useColorModeValue, Button } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React from "react";
import bukaData from "assets/img/panduan/buka.gif";
import c2Data from "assets/img/panduan/c2.gif";
import c3Data from "assets/img/panduan/c3.gif";
import c4Data from "assets/img/panduan/c4.gif";
import analisisData from "assets/img/panduan/analisis.gif";
import Swal from 'sweetalert2'

export default function Information(props) {
  const panduan1 = async () => {
    Swal.fire({
      title: "Buka gambar!",
      text: "Tekan tombol Buka gambar cephalometri kemudian sesuaikan posisi sesuai panduan dimana bagian wajah ada di sebelah kanan!",
      imageUrl: bukaData,
      imageWidth: 459,
      imageHeight: 427,
      imageAlt: "Custom image",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Paham!"
    }).then((result) => {
      if (result.isConfirmed) {
        // Langkah berikutnya
      }
    });
    
  }
  const panduan2 = async () => {
    Swal.fire({
      title: "Penitikan Cervikal 2!",
      text: "Titik pertama di tekan pada posisi batas dasar posterior, di lanjut titik tengah pada batas bawah inferior, dan di lanjut titik akhir pada posisi batas dasar anterior!",
      imageUrl: c2Data,
      imageWidth: 459,
      imageHeight: 427,
      imageAlt: "Custom image",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Paham!"
    }).then((result) => {
      if (result.isConfirmed) {
        // Langkah berikutnya
      }
    });
    
  }
  const panduan3 = async () => {
    Swal.fire({
      title: "Penitikan Cervikal 3!",
      text: "Titik pertama di tekan pada posisi batas dasar posterior, di lanjut titik ke-2 pada titik tengah batas bawah inferior, di lanjut titik ke-3 pada posisi batas dasar anterior, di lanjut titik ke-4 pada posisi batas atas anterior, dan di lanjut titik ke-5 pada posisi batas atas posterior!",
      imageUrl: c3Data,
      imageWidth: 459,
      imageHeight: 427,
      imageAlt: "Custom image",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Paham!"
    }).then((result) => {
      if (result.isConfirmed) {
        // Langkah berikutnya
      }
    });
    
  }
  const panduan4 = async () => {
    Swal.fire({
      title: "Penitikan Cervikal 4!",
      text: "Titik pertama di tekan pada posisi batas dasar posterior, di lanjut titik ke-2 pada titik tengah batas bawah inferior, di lanjut titik ke-3 pada posisi batas dasar anterior, di lanjut titik ke-4 pada posisi batas atas anterior, dan di lanjut titik ke-5 pada posisi batas atas posterior!",
      imageUrl: c4Data,
      imageWidth: 459,
      imageHeight: 427,
      imageAlt: "Custom image",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Paham!"
    }).then((result) => {
      if (result.isConfirmed) {
        // Langkah berikutnya
      }
    });
    
  }
  const panduan5 = async () => {
    Swal.fire({
      title: "Analis gambar!",
      text: "Tekan tombol analisis gambar, kemudian tunggu dan liat hasil dari analisis CS pada gambar cephalometri yang telah anda lakukan!",
      imageUrl: analisisData,
      imageWidth: 459,
      imageHeight: 427,
      imageAlt: "Custom image",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Paham!"
    }).then((result) => {
      if (result.isConfirmed) {
        // Langkah berikutnya
      }
    });
    
  }
  const { title, popup, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const bg = useColorModeValue("white", "navy.700");

  return (
    <Card bg={bg} {...rest}>
      <Box>
        <Text fontWeight='500' color={textColorSecondary} fontSize='sm'  mb={1} >
        1️⃣Tahap pertama Buka Gambar
        </Text>
        <Button onClick={panduan1} me='10px'
              mb={4} 
              colorScheme="orange" 
              style={{ fontSize: '12px', padding: '4px 8px' }}>
              Lihat panduan 1
        </Button>
        <Text fontWeight='500' color={textColorSecondary} fontSize='sm' mb={1} >
        2️⃣ Tahap kedua Penitikan pada Cervical 2
        </Text>
        <Button onClick={panduan2} me='20px'
               mb={4} 
               colorScheme="orange" 
               style={{ fontSize: '12px', padding: '4px 8px' }}>
              Lihat panduan 2
        </Button>
        <Text fontWeight='500' color={textColorSecondary} fontSize='sm' mb={1} >
        3️⃣Tahap ketiga Penitikan pada Cervical 3
        </Text>
        <Button onClick={panduan3} me='20px'
               mb={4} 
               colorScheme="orange" 
               style={{ fontSize: '12px', padding: '4px 8px' }}>
              Lihat panduan 3
        </Button>
        <Text fontWeight='500' color={textColorSecondary} fontSize='sm' mb={1} >
        4️⃣ Tahap keempat Penitikan pada Cervical 4
        </Text>
        <Button onClick={panduan4} me='20px'
               mb={4} 
               colorScheme="orange" 
               style={{ fontSize: '12px', padding: '4px 8px' }}>
              Lihat panduan 4
        </Button>
        <Text fontWeight='500' color={textColorSecondary} fontSize='sm' mb={1} >
        5️⃣Tahap kelima Analisis Gambar
        </Text>
        <Button onClick={panduan5} me='20px'
               mb={4} 
               colorScheme="orange" 
               style={{ fontSize: '12px', padding: '4px 8px' }}>
              Lihat panduan 5
        </Button>
      </Box>
    </Card>
  );
}
