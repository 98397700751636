// Chakra imports
import {
  SimpleGrid,
  Text,
  useColorModeValue,
  Button
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React from "react";
// Assets
import Information from "views/admin/profile/components/Information";
export default function Banner(props) {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  return (
    <Card mb={{ base: "0px", "2xl": "20px" }}>
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='2xl'
        mt='10px'
        mb='4px'>
        Panduan Penggunaan Analisis CVMS
      </Text>
      <Text color={textColorSecondary} fontSize='md' me='26px' mb='10px'>
        Berikut panduan untuk analisis pada gambar Cervical pada tiap proses yang harus di ikuti secara sistematis atau terurut agar perhitungan matematis berjalan sempurna semana mestinya.
      </Text>
      <SimpleGrid columns='1' gap='20px'>
      <Information />
      </SimpleGrid>
    </Card>
  );
}
